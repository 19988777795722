<template>
  <div>
    <main-nav :activeIndex="activeIndex"></main-nav>
    <div class="solution-header-container">
      <div class="solution-header-text">
        <h1 data-caption-delay="0" class="wow fadeInLeft">信息安全测试</h1>
        <p data-caption-delay="100" class="wow fadeInRight">可实现总线类SecOC/TLS/IPSec相关测试及相关技术支持，也可实现传统信息安全测试，如：网络渗透等方面测试等</p>
        <div class="solution-text-button wow fadeInUp" @click="linkUrl(chatUrl)"><div>立即咨询</div></div>
      </div>
    </div>
    <section class="solution-intros wow fadeInLeft">
      <div class="container">
        <div class="normal-title">解决方案介绍</div>
        <div class="all-intro">
          <el-row>
            <el-col :span="24">
              <p class="intro-detail">
                对于具备信息安全功能的控制器进行信息安全方面测试（如：硬件、软件、渗透、漏洞扫描等），旨在验证控制器的合规性，暴露影响信息安全的因素，为修补漏洞提供支持。
              </p>
              <p class="intro-detail">
                对CAN/CANFD/Ethernet等网络通讯安全方案进行测试（如：SecOC、TLS、IPsec等），旨在通过测试验证相关协议一致性。
              </p>
            </el-col>
          </el-row>
        </div>
      </div>
    </section>
    <section class="solution-functions vehicle-solution-sections electric-solution-function wow fadeInRight">
      <div class="container">
        <div class="normal-title">功能特点</div>
        <div
            class="all-function"
            @mouseenter="$refs.mySwiper.swiper.autoplay.stop()"
            @mouseleave="$refs.mySwiper.swiper.autoplay.start()"
        >
          <swiper :options="swiperOption" ref="mySwiper">
            <swiper-slide v-for="(item, index) in functionList" :key="index">
              <div class="card-item">
                <el-card class="solution-function-card" shadow="hover">
                  <div class="card-icon" style="margin-top: 30px">
                    <img :src=" item.icon " class="svg-icon">
                  </div>
                  <div class="card-text">
                    <p>{{ item.content }}</p>
                  </div>
                </el-card>
              </div>
            </swiper-slide>
            <!-- 根据情况引入控件 -->
            <!--            <div class="swiper-pagination" slot="pagination"></div>-->
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>
    </section>
    <section class="ethernet-solution-components wow fadeInLeft">
      <div class="container">
        <div class="normal-title security-test-normal-title">测试内容及介绍</div>
        <div class="solution-components">
          <el-row>
            <el-col :span="24">
              <div class="components-table">
                <el-table
                    style="font-size: 14px"
                    :data="componentsTableData"
                    :span-method="componentsArraySpanMethod"
                    :header-cell-style="{background:'#296CA8',color:'#FFFFFF'}"
                    size="mini"
                    border
                >
                    <el-table-column prop="type" label="测试分类" align="center" :width="tableWidth().column1"/>
                    <el-table-column prop="content" label="测试内容" :width="tableWidth().column2"/>
                    <el-table-column prop="introduction" label="内容介绍" />
                </el-table>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </section>
    <bzInfo style="margin-top: 0"/>
    <main-footer></main-footer>
  </div>
</template>

<script>
import mainNav from '@/components/MainNav'
import mainFooter from '@/components/MainFooter'
import bzInfo from '@/components/BzInfo'
export default {
  name: "securityTest",
  components: {
    mainNav,
    mainFooter,
    bzInfo
  },
  data(){
    return {
      activeIndex: '2-6',
      functionList: [
        {
          icon: require('../../../assets/images/solution-securityTest-function-icon-1.svg'),
          content: '实现自动及半自动测试'
        },
        {
          icon: require('../../../assets/images/solution-securityTest-function-icon-2.svg'),
          content: '模拟真实环境模拟攻击'
        },
        {
          icon: require('../../../assets/images/solution-electric-function-icon-3.svg'),
          content: '完整的软、硬件测试体系'
        },
        {
          icon: require('../../../assets/images/solution-electric-function-icon-4.svg'),
          content: '可定制化测试方案'
        },
      ],
      swiperOption: {
        spaceBetween: 20, // 图片之间的间距
        centeredSlides: false, // 居中还是从图1开始
        slidesPerView: 2, // 一屏幕显示几个? 数字或者默认  auto 自动。
        breakpointsInverse: true,
        breakpoints: {
          //当宽度大于等于1100
          1100: {
            slidesPerView: 4,
            spaceBetween: 10
          }
        },
        notNextTick: true, // true:加载后允许触发事件 false:加载后不可以触发事件
        // loop: true, // 循环吗
        initialSlide: 0, // 从第几个开始
        autoplay: {
          delay: 5000, // 等5秒下一个
          disableOnInteraction: false // 中间滑动一下，取消自动吗？
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }, // 下按钮
        speed: 800, // 滑动时候动画的速度
        paginationClickable: true, // 下面按钮让点吗
        navigation: {
          prevEl: '.swiper-button-prev', // 左侧按钮
          nextEl: '.swiper-button-next' // 右侧按钮
        },
        // effect: 'fade', // 渐入效果
        watchSlidesProgress: true, // 开启这个参数来计算每个slide的progress
        watchSlidesVisibility: true  // 先要开启watchSlidesProgress参数，如果开启watchSlidesVisibility，则会在每个slide增加一个指示该slide的progress值得classname
        // autoHeight: true  // 图片高度自适应
      },
      spanArr: [],//用于存放每一行记录的合并数
      pos: 0,// 索引
      componentsTableData: [
        {
          type: "硬件测试",
          content: "PCB板敏感信息测试",
          introduction: "调试接口判定及检测所暴漏对入侵有利的信息"
        },
        {
          type: "硬件测试",
          content: "调试接口测试",
          introduction: "UART、JTAG口，检查通过调试接口完成程序修改"
        },
        {
          type: "硬件测试",
          content: "固件提取测试",
          introduction: "UART、JTAG口，检测通过调试接口提取系统固件"
        },
        {
          type: "软件测试",
          content: "软件基础测试",
          introduction: "软件编码测试、静态代码分析，接口及功能测试"
        },
        {
          type: "软件测试",
          content: "操作系统测试",
          introduction: "系统账户安全检查、漏洞扫描、访问控制、ADB服务测试等"
        },
        {
          type: "软件测试",
          content: "应用层测试",
          introduction: "未授权执行测试、逻辑安全测试、应用加固测试"
        },
        {
          type: "软件测试",
          content: "安全启动",
          introduction: "软件安全启动逻辑测试"
        },
        {
          type: "接口测试",
          content: "漏洞检测",
          introduction: "指定漏洞库扫描、其它已知漏洞扫描"
        },
        {
          type: "接口测试",
          content: "拒绝服务测试",
          introduction: "Dos及Ddos攻击"
        },
        {
          type: "渗透测试",
          content: "",
          introduction: "模拟真实环境的攻击、以确定破坏安全目标的方法"
        },
        {
          type: "数据测试",
          content: "敏感数据泄露测试",
          introduction: "检测本地存储泄露敏感信息"
        },
        {
          type: "数据测试",
          content: "配置文件安全测试",
          introduction: "检查是否能被任意修改和备份"
        },
        {
          type: "数据测试",
          content: "数据库文件安全测试",
          introduction: "检查数据库使用的安全存储方式，检查数据库鉴权"
        },
        {
          type: "数据测试",
          content: "日志记录测试",
          introduction: "测试log是否存在敏感信息"
        },
        {
          type: "SecOC测试",
          content: "SecOC主节点测试",
          introduction: "主节点FV生成逻辑测试，协议格式内容测试，攻击测试"
        },
        {
          type: "SecOC测试",
          content: "SecOC从节点测试",
          introduction: "从节点FV生成逻辑测试，协议格式内容测试，攻击测试"
        },
        {
          type: "SecOC测试",
          content: "密钥学习测试",
          introduction: "密钥写入顺序测试"
        },
        {
          type: "TLS测试",
          content: "接入认证测试",
          introduction: "OBD及远程接入测试"
        },
        {
          type: "TLS测试",
          content: "安全刷写测试",
          introduction: "基于安全接入的刷写测试"
        },
        {
          type: "TLS测试",
          content: "通讯认证测试",
          introduction: "通讯节点间建立通讯的认证测试"
        },
        {
          type: "TLS测试",
          content: "证书罐装",
          introduction: "证书罐装流程逻辑测试"
        },
        {
          type: "IPsec测试",
          content: "密钥罐装测试",
          introduction: "密钥写入流程逻辑测试"
        },
        {
          type: "IPsec测试",
          content: "协议格式测试",
          introduction: "IPSec协议格式内容进行测试"
        },
        {
          type: "IPsec测试",
          content: "安全报文校验测试",
          introduction: "对于IPSec加密报文进行重放及攻击"
        },
        {
          type: "IPsec测试",
          content: "重协商测试",
          introduction: "验证身份及安全保护"
        },
      ],
      chatUrl:"https://doc.weixin.qq.com/forms/AOEAXQcgAAkAe4APAaVAD0jHqcmrcFRKf",
    }
  },
  mounted: function() {
    new this.$wow.WOW().init();
    this.getSpanArr(this.componentsTableData);
    document.title = "信息安全测试 - 解决方案 - 东信创智";
  },
  methods: {
    linkUrl(url){
      window.open(url,'_blank') // 在新窗口打开外链接
      // window.location.href =this.indexro;  //在本页面打开外部链接
    },
    getSpanArr(data) {
      // data就是我们从后台拿到的数据
      for (let i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanArr.push(1);
          this.pos = 0;
        } else {
          // 判断当前元素与上一个元素是否相同
          if (data[i].type === data[i - 1].type) {
            this.spanArr[this.pos] += 1;
            this.spanArr.push(0);
          } else {
            this.spanArr.push(1);
            this.pos = i;
          }
        }
      }
    },
    // eslint-disable-next-line no-unused-vars
    componentsArraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        const _row = this.spanArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          // [0,0] 表示这一行不显示， [2,1]表示行的合并数
          rowspan: _row,
          colspan: _col
        };
      }
    },
    tableWidth(){
      if(document.documentElement.clientWidth < 1100){
        return {
          column1: 100,
          column2: 120,
        }
      } else {
        return {
          column1: 180,
          column2: 320,
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
